import Swiper from 'swiper';
import Vue from 'vue/dist/vue.js';
import throttle from 'lodash.throttle';
import { Forms } from './forms';
import { Ajax } from './ajax';
import { Notification } from './notifications';
import animatedCricket from './animatedCricket';
import importantCarousel from './nyan';
// import toggleContrast from './contrast';
import 'eventlistener-polyfill';
// import 'css-polyfills';
// import imagesLoaded from 'imagesloaded';

import { whichAnimationEvent,
         whichTransitionEvent } from './helpers';

import VueMask from 'v-mask';
Vue.use(VueMask);

import Multiselect from 'vue-multiselect';
Vue.component('multiselect', Multiselect);

(function() {

  const animationEvent  = whichAnimationEvent();
  const transitionEvent  = whichTransitionEvent();

  const HomeSlider = new Swiper(document.querySelector('[data-carousel="home"]'), {
    speed: 400,
    loop: true,
    watchOverflow: true,
    // autoplay: {
    //   delay: 20000
    // },
    pagination: {
      el: '[data-carousel="home"] .swiper-pagination',
      type: 'bullets',
      renderBullet: (index, className) => `<button class="${className}" type="button" aria-title="${index}">
        <span class="main">
          <span class="left"></span>
          <span class="right"></span>
        </span>
      </button>`,
      clickable: true
    },
    navigation: {
      nextEl: '[data-carousel="home"] .swiper-button-next',
      prevEl: '[data-carousel="home"] .swiper-button-prev',
    },
    on: {
      init() {
        Array.from(document.querySelectorAll('.home-carousel__canvas')).forEach((canvas) => {
          // new animatedCricket(canvas);
          // imagesLoaded(cricket, () => {
          //   var nextParticle = new NextParticle(cricket);
          //   nextParticle.particleGap = 2;
          //   nextParticle.noise = 10;
          //   nextParticle.mouseForce = 60;
          //   nextParticle.gravity = 0.05;
          //   nextParticle.size = Math.min(window.innerWidth, window.innerHeight);
          //   nextParticle.colorize = false;
          //   nextParticle.tint = '#FF00FF';
          //   nextParticle.width = nextParticle.size;
          //   nextParticle.height = nextParticle.size;
          //   nextParticle.maxWidth = '50%';
          //   nextParticle.minWidth = '50%';
          //   // nextParticle.start();

          //   var redraw = function() {
          //     nextParticle.initPosition = 'none';
          //     nextParticle.initDirection = 'none';
          //     nextParticle.fadePosition = 'none';
          //     nextParticle.fadeDirection = 'none';
          //     nextParticle.start();
          //   };

          //   window.onresize = function() {
          //     nextParticle.size = Math.min(window.innerWidth, window.innerHeight);
          //     nextParticle.width = nextParticle.size;
          //     nextParticle.height = nextParticle.size;
          //     redraw();
          //   };
          // })
        })
      },
      slideChangeTransitionStart() {
        let activeSlide = this.slides[this.activeIndex];

        if (activeSlide.querySelector('.home-carousel__canvas')) {
          new animatedCricket(activeSlide.querySelector('.home-carousel__canvas'));
        }

        try {
          var color = activeSlide.querySelector('[data-header-color]').dataset.headerColor;
          this.el.style.color = color;
          if (window.pageYOffset < this.el.offsetHeight) document.getElementById('fixed_header').style.color = color;

        } catch(e) {
          this.el.style.color = '#000';
          if (window.pageYOffset < this.el.offsetHeight) document.getElementById('fixed_header').style.color = '#000';
        }
      }
    }
  });

  new importantCarousel();

  const HomePagination = document.querySelector('[data-carousel="home"] .swiper-pagination');

  if (HomePagination) HomePagination.addEventListener(animationEvent, (e) => { if (e.target.classList.contains('left')) HomeSlider.slideNext(); });

  // Carousel Cases

  const CasesCarousel = document.querySelector('[data-carousel="cases"]');

  if (CasesCarousel) {
    let cases_carousel_options = {
      speed: 400,
      loop: true,
      watchOverflow: true,
      on: {
        slideChangeTransitionStart() {
          Array.from(this.el.querySelectorAll('video')).forEach((video) => {
            video.pause();
            video.currentTime = 0;
          })
        }
      }
    }

    if (CasesCarousel.querySelectorAll('.swiper-slide').length > 1) {
      cases_carousel_options.navigation = {
        nextEl: '.cases-carousel .swiper-button-next',
        prevEl: '.cases-carousel .swiper-button-prev',
      }
    } else {
      document.querySelector('.cases-carousel__wrapper').hidden = true;
    }

    const CasesSlider = new Swiper(CasesCarousel, cases_carousel_options);

    const CasesPagination = document.querySelector('[data-carousel="cases"] .swiper-pagination');

    if (CasesPagination) CasesPagination.addEventListener(animationEvent, (e) => { if (e.target.classList.contains('left')) CasesSlider.slideNext(); });

  }

  // Menu Button

  document.getElementById('close_nav').addEventListener('click', () => { document.body.classList.toggle('is-menu--open') }, true);
  document.getElementById('open_nav').addEventListener('click', () => { document.body.classList.toggle('is-menu--open') }, true);

  // Hidden Menu and Color Changes

  function getPosition(element, log) {
    let x = 0;
    let y = 0;
    let el = element;

    while (el) {
      if (log) console.log(el, el.offsetTop, el.scrollTop, el.clientTop);
      x += (el.offsetLeft - (el.scrollLeft + el.clientLeft));
      y += (el.offsetTop - (el.scrollTop + el.clientTop));
      el = el.offsetParent;
    }

    return { x, y };
  };

  window.onscroll = throttle(() => {
    const anchors = Array.from(document.querySelectorAll('[data-header-color]'));
    const footerOffset = getPosition(document.querySelector('.footer'));
    const pageOffset = window.pageYOffset;

    let i = 0;
    let stop = false;

    if (pageOffset > (window.innerHeight / 10)) {
      document.body.classList.add('has--scrolled');
    } else {
      document.body.classList.remove('has--scrolled');
    }

    if (pageOffset >= window.innerHeight) {
      document.body.classList.add('is--below-fold');

      while (i < anchors.length && !stop) {
        const bounds = anchors[i].getBoundingClientRect();
        stop = bounds.top - 56 > (-window.innerHeight / 2);

        if (!stop) i++;
      }

      if (pageOffset >= (footerOffset.y - window.innerHeight)) {
        document.body.classList.add('is--footer');
      } else {
        document.body.classList.remove('is--footer');
      }

      try {
        var color = anchors[i].dataset.headerColor;
        document.getElementById('fixed_header').style.color = color;

        if (document.getElementById('home_nav')) {
          document.getElementById('home_nav').style.color = color;

          const activePanel = Array.from(document.getElementById('home_nav').querySelectorAll('a')).find((link) => {
            return `#${anchors[i].id}` === link.hash;
          });

          const activeItem = activePanel.parentNode;

          Array.from(activeItem.parentNode.children).filter((el) => {
            return el !== activeItem;
          }).forEach((el) => {
            el.classList.remove('is--active');
          });

          activeItem.classList.add('is--active');
        }

      } catch(e) {
        document.getElementById('fixed_header').style.color = '#000';
      }
    } else if (HomeSlider) {
      document.body.classList.remove('is--below-fold');
      try {
        var color = HomeSlider.slides[HomeSlider.activeIndex].querySelector('[data-header-color]').dataset.headerColor;
        document.getElementById('fixed_header').style.color = color;
      } catch(e) {
        document.getElementById('fixed_header').style.color = '#000';
      }
    }
  }, 10);

  Array.from(document.querySelectorAll('[href*="#"]:not([href$="#"])')).forEach((el) => {
    el.addEventListener('click', (event) => {

      const target = document.querySelector(el.hash);
      // const header = document.getElementById('ptnr_header');

      if (target) {
        event.preventDefault();
        document.body.classList.remove('is-menu--open');
        if (target) window.scrollTo({
          behavior: 'smooth',
          left: 0,
          top: target.offsetTop
        });
      }
    })
  });

  // Contato Home

  if (document.getElementById('vueform')) {
    const HomeContato = new Vue({
      el: '#vueform',
      data: {
        name: '',
        email: '',
        message: '',
        company: '',
        phone: '',
        files: [],
        file: null,
        type: 1,
        interests: [],
        submitting: false,
      },
      created() {
        if (location.hash === '#quero-ser-um-grilo' || location.hash === '#vamos-saltar-juntos') {
          this.type = (location.hash === '#quero-ser-um-grilo') ? 3 : 2;

          window.scrollTo({
            behavior: 'smooth',
            left: 0,
            top: document.getElementById('home_contato').offsetTop
          });
        }
      },
      computed: {
        filesize() {
          if (!this.files.length) return 0;

          return this.files.reduce((sum, file) => {
            sum += file.size;
            return sum;
          }, 0);
        },
      },
      methods: {
        loadFiles(event) {
          if (event.target && event.target.files) {
            this.files = this.files.concat(Array.from(event.target.files));
          }
        },
        onSubmit(event) {
          if (this.submitting) return false;

          this.submitting = true;
          // let formData = new FormData(event.target);

          // console.log(event.target['g-recaptcha-response'].value);

          if (event.target['g-recaptcha-response'] && !event.target['g-recaptcha-response'].value) {
            this.submitting = false;
            if (grecaptcha) grecaptcha.reset();
            return false;
          }

          if (this.filesize >= 5000000) {
            this.submitting = false;
            return false;
          }

          if (this.type == 3 && !this.files.length) {
            this.submitting = false;
            return false;
          }

          Ajax((connectionError, content) => {
            this.submitting = false;
            if (grecaptcha) grecaptcha.reset();

            if (connectionError) {
              return Notification({
                type: 'error',
                message: connectionError,
                redirect: content.redirect
              });
            }

            if (content.status === 'error') {
              if (content.errors && content.errors.length) {
                content.errors.forEach((err) => {
                  let input = this.$el.querySelector(`[name="${err.name}"]`);

                  if (input) {
                    let parent = input.parentNode;

                    parent.classList.add('is--invalid');

                    let messagebox = parent.querySelector(`[data-message-for="${err.name}"]`);

                    if (!messagebox || !messagebox.length) {
                      messagebox = document.createElement('div');
                      messagebox.dataset.messageFor = err.name;
                      messagebox.dataset.formGenerated = true;

                      parent.insertBefore(messagebox, input.nextSibling);
                    }

                    messagebox.setAttribute('aria-hidden', false);
                    messagebox.innerHTML = err.message;
                  }
                });
              }
            }

            if (content.status === 'success' && dataLayer) {
              var contatoType = 'Vamos Conversar';
              if (this.type == 2) {
                contatoType = 'Vamos Saltar Juntos'
              } else if (this.type == 3) {
                contatoType = 'Quero ser um grilo!';
              }

              dataLayer.push({event: 'enviarContato', contactType: contatoType});
            }

            if (content.notifications && content.notifications.length) {
              return content.notifications.forEach((n) => {
                Notification({
                  type: n.type,
                  title: n.title,
                  message: n.message,
                  redirect: content.redirect,
                  calltoaction: n.calltoaction
                })
              })
            }

            return false;
          }, {
            method: event.target.getAttribute('method'),
            action: event.target.getAttribute('action'),
            form: event.target,
            appendData: this.interests.map((c) => { return { name: 'interest[]', value: c } } )
          }).call(this, event);
        },
        selecionarContato(contactType) {
          dataLayer.push({event: 'selecionarContato', contactType});
        }
      }
    })
  }

  // SVG de Expertises

  let expertises_svg = document.getElementById('expertises_image');
  let expertises_interval;

  if (expertises_svg) {
    let expertises_active = expertises_svg.firstElementChild;
    let expertises_content = document.getElementById('expertises_content');

    function setExpertiseContent() {
      expertises_active.classList.add('is--lighten');

      if (expertises_content) {
        expertises_content.textContent = expertises_active.dataset.content;
        expertises_content.classList.add('is--lighten');
      }
    }

    function nextExpertiseContent() {
      expertises_active.addEventListener(transitionEvent, setExpertiseContent, { once: true })
      if (expertises_content) expertises_content.classList.remove('is--lighten');
      expertises_active.classList.remove('is--lighten');
      expertises_active = expertises_active.nextElementSibling || expertises_svg.firstElementChild;
    }

    expertises_svg.addEventListener('mouseover', (event) => {
      let target = event.target.matches('g') ? event.target : (event.target.parentNode.matches('g') ? event.target.parentNode : null);
      if (target) {
        clearInterval(expertises_interval);

        if (expertises_content) expertises_content.classList.remove('is--lighten');
        expertises_active.classList.remove('is--lighten');
        expertises_active = target;
        setExpertiseContent();
      }
    }, true);

    expertises_svg.addEventListener('mouseout', (event) => {
      let target = event.target.matches('g') ? event.target : (event.target.parentNode.matches('g') ? event.target.parentNode : null);

      if (target) {
        nextExpertiseContent();
        expertises_interval = setInterval(nextExpertiseContent, 6000);
      }

    }, true);

    setExpertiseContent();

    expertises_interval = setInterval(nextExpertiseContent, 6000);
  }

  // Forms

  Forms.init({
    'clean-after-success': true,
    'validate': true
  });

  sessionStorage.getItem('contrast') && +sessionStorage.getItem('contrast') && document.body.classList.add('is--contrasted');

  if(document.querySelector('[data-contrast]')) {
    document.querySelector('[data-contrast]').addEventListener('click', () => {
      document.body.classList.toggle('is--contrasted');

      sessionStorage.setItem('contrast', document.body.classList.contains('is--contrasted') ? 1 : 0);
    }, true);
  }

  if (sessionStorage.getItem('fontsize')) document.body.style.fontSize = sessionStorage.getItem('fontsize') + 'px';

  if (document.querySelector('[data-fontsize-decrease]')) {
    document.querySelector('[data-fontsize-decrease]').addEventListener('click', (event) => {
      event.preventDefault();
      let fontsize = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('font-size'));

      document.body.style.fontSize = --fontsize + 'px';

      sessionStorage.setItem('fontsize', fontsize);
    }, true);
  }

  if (document.querySelector('[data-fontsize-increase]')) {
    document.querySelector('[data-fontsize-increase]').addEventListener('click', (event) => {
      event.preventDefault();
      let fontsize = parseInt(window.getComputedStyle(document.body, null).getPropertyValue('font-size'));

      document.body.style.fontSize = ++fontsize + 'px';

      sessionStorage.setItem('fontsize', fontsize);
    }, true);
  }

  if (document.getElementById('trendspot-form')) {
    document.getElementById('trendspot-form').addEventListener('submit:success', () => { if(grecaptcha) grecaptcha.reset(); if (dataLayer) dataLayer.push({event: 'enviarTrendspot'}); });
    document.getElementById('trendspot-form').addEventListener('submit:error', () => { if(grecaptcha) grecaptcha.reset(); });
  }

  if (document.querySelectorAll('.home-carousel__canvas').length) {
    [...document.querySelectorAll('.home-carousel__canvas')].forEach((el) => {
      return new animatedCricket(el);
    });
  }

})();
