// import moment from 'moment';
// import 'moment/locale/pt-br';

function isEmail(email) {
  return /^([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x22([^\x0d\x22\x5c\x80-\xff]|\x5c[\x00-\x7f])*\x22))*\x40([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d)(\x2e([^\x00-\x20\x22\x28\x29\x2c\x2e\x3a-\x3c\x3e\x40\x5b-\x5d\x7f-\xff]+|\x5b([^\x0d\x5b-\x5d\x80-\xff]|\x5c[\x00-\x7f])*\x5d))*$/.test( email );
}

// function isCpf(cpf) {
//   return CPF.validate(cpf);
// }

// function isDate(date) {
//   return moment(date, 'DD/MM/YYYY').isValid();
// }

// function isBeforeToday(date) {
//   let m = moment(date, 'DD/MM/YYYY');
//   return m.isValid() && m.isSameOrBefore(moment());
// }

function isPhone(phone) {
  return /^\(\d{2}\)\s\d{4,5}-\d{4}$/.test(phone);
}

function isZipcode(zipcode) {
  return /^\d{5}-\d{3}$/.test(zipcode)
}

function isYoutube(youtube) {
  var v = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
  return (youtube.match(v)) ? RegExp.$1 : false;
}

function filesize(filesize) {
  return ;
}

export {
  isEmail,
  isPhone,
  isZipcode,
  isYoutube,
  filesize
};
