export default class animatedCricket {
  constructor(container, settings={}) {
    this.container = container;
    this.line_width = 25;

    this.buildSvg.call(this);
    window.addEventListener('resize', () => {
      setTimeout(this.buildSvg.bind(this), 500);
    });
  }

  buildSvg() {
    this.width = this.container.clientWidth || document.body.clientWidth;
    this.height = this.container.clientHeight || window.innerHeight;
    this.stroke_fill = '#212125';
    this.stroke_width = 3;
    this.cricket_width = this.line_width * 14;
    this.cricket_height = this.line_width * 14;

    // console.log(this.width, this.height);

    this.spacing_top = (this.height - this.cricket_height) / 2;
    this.spacing_left = (this.width - this.cricket_width) / 2;

    // Essa parte é jacu para ajustar com o título do slide
    const title = this.container.parentNode.querySelector('.home-carousel__title');
    const title_width = title.clientWidth;
    const title_offset = title.getBoundingClientRect().left - this.container.parentNode.getBoundingClientRect().left

    this.spacing_left = Math.max(this.spacing_left, title_width + title_offset + ((this.width - (title_width + title_offset)) - this.cricket_width) / 2);
    // Fim da parte jacu

    this.spacing_left

    this.svg = this.getNode('svg', {width: this.width, height: this.height, viewBox: `0 0 ${this.width} ${this.height}`});

    this.id = this.makeid();

    this.polyline_1 = this.svg.appendChild(this.getNode('polyline', {
      'class': `${this.id}_0`,
      stroke: this.stroke_fill,
      'stroke-width': this.stroke_width,
      fill: 'none',
      points: `${this.getPoint('start', 11)}
        ${this.getPoint(2, 11)}
        ${this.getPoint(2, 12)}
        ${this.getPoint(1, 12)}
        ${this.getPoint(1, 9)}
        ${this.getPoint(2, 9)}
        ${this.getPoint(2, 10)}
        ${this.getPoint(3, 10)}
        ${this.getPoint(3, 11)}
        ${this.getPoint(4, 11)}
        ${this.getPoint(4, 13)}
        ${this.getPoint(6, 13)}
        ${this.getPoint(6, 14)}
        ${this.getPoint(5, 14)}
        ${this.getPoint(5, 12)}
        ${this.getPoint(9, 12)}
        ${this.getPoint(9, 14)}
        ${this.getPoint(8, 14)}
        ${this.getPoint(8, 13)}
        ${this.getPoint(10, 13)}
        ${this.getPoint(10, 11)}
        ${this.getPoint(11, 11)}
        ${this.getPoint(11, 10)}
        ${this.getPoint(12, 10)}
        ${this.getPoint(12, 9)}
        ${this.getPoint(13, 9)}
        ${this.getPoint(13, 12)}
        ${this.getPoint(12, 12)}
        ${this.getPoint(12, 11)}
        ${this.getPoint(14, 11)}
        ${this.getPoint(14, 5)}`
    }));

    this.polyline_2 = this.svg.appendChild(this.getNode('polyline', {
      'class': `${this.id}_1`,
      stroke: this.stroke_fill,
      'stroke-width': this.stroke_width,
      fill: 'none',
      points: `${this.getPoint('end', 5)}
        ${this.getPoint(12, 5)}
        ${this.getPoint(12, 6)}
        ${this.getPoint(11, 6)}
        ${this.getPoint(11, 7)}
        ${this.getPoint(10, 7)}
        ${this.getPoint(10, 5)}
        ${this.getPoint(9, 5)}
        ${this.getPoint(9, 2)}
        ${this.getPoint(11, 2)}
        ${this.getPoint(11, 0)}
        ${this.getPoint(12, 0)}
        ${this.getPoint(12, 1)}
        ${this.getPoint(10, 1)}
        ${this.getPoint(10, 3)}
        ${this.getPoint(8, 3)}
        ${this.getPoint(8, 4)}
        ${this.getPoint(6, 4)}
        ${this.getPoint(6, 3)}
        ${this.getPoint(4, 3)}
        ${this.getPoint(4, 1)}
        ${this.getPoint(2, 1)}
        ${this.getPoint(2, 0)}
        ${this.getPoint(3, 0)}
        ${this.getPoint(3, 2)}
        ${this.getPoint(5, 2)}
        ${this.getPoint(5, 5)}
        ${this.getPoint(4, 5)}
        ${this.getPoint(4, 7)}
        ${this.getPoint(3, 7)}
        ${this.getPoint(3, 6)}
        ${this.getPoint(2, 6)}
        ${this.getPoint(2, 5)}
        ${this.getPoint(0, 5)}
        ${this.getPoint(0, 11)}`
    }));

    this.polygon_1 = this.svg.appendChild(this.getNode('polygon', {
      'class': `${this.id}_2`,
      stroke: this.stroke_fill,
      'stroke-width': this.stroke_width,
      fill: 'none',
      points: `${this.getPoint(5, 7)}
        ${this.getPoint(6, 7)}
        ${this.getPoint(6, 8)}
        ${this.getPoint(5, 8)}
        ${this.getPoint(5, 7)}`
    }));

    this.polygon_2 = this.svg.appendChild(this.getNode('polygon', {
      'class': `${this.id}_2`,
      stroke: this.stroke_fill,
      'stroke-width': this.stroke_width,
      fill: 'none',
      points: `${this.getPoint(8, 7)}
      ${this.getPoint(9, 7)}
      ${this.getPoint(9, 8)}
      ${this.getPoint(8, 8)}
      ${this.getPoint(8, 7)}`
    }));

    this.polyline_0_length = this.calculateLength(this.polyline_1.points);
    this.polyline_1_length = this.calculateLength(this.polyline_2.points);
    this.polyline_2_length = this.calculateLength(this.polygon_1.points);

    this.style = `<style>
      .${this.id}_0 {
        stroke-dasharray: ${this.polyline_0_length} ${this.polyline_0_length + 2};
        stroke-dashoffset: ${this.polyline_0_length + 1};
        animation: ${this.id}_draw 3000ms linear 0ms forwards;
      }
      .${this.id}_1 {
        stroke-dasharray: ${this.polyline_1_length} ${this.polyline_1_length + 2};
        stroke-dashoffset: ${this.polyline_1_length + 1};
        animation: ${this.id}_draw 3000ms linear 0ms forwards;
      }
      .${this.id}_2 {
        stroke-dasharray: ${this.polyline_2_length} ${this.polyline_2_length + 2};
        stroke-dashoffset: ${this.polyline_2_length + 1};
        animation: ${this.id}_draw 1000ms linear 2500ms forwards;
      }
      @keyframes ${this.id}_draw {
        100% {
          stroke-dashoffset: 0;
        }
      }
    </style>`;

    this.svg.innerHTML = this.svg.innerHTML + this.style;

    this.container.innerHTML = '';
    this.container.appendChild(this.svg);
  }

  makeid() {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let text = '';

    for (let i = 0; i < 5; i++)
      text += possible.charAt(Math.floor(Math.random() * possible.length));

    return text;
  }

  getPoint(row, column) {
    if (row === 'start') {
      return `0 ${this.line_width * column + this.spacing_top}`;
    }

    if (row === 'end') {
      return `${this.width} ${this.line_width * column + this.spacing_top}`;
    }

    return `${this.line_width * row + this.spacing_left} ${this.line_width * column + this.spacing_top}`;
  }

  getNode(n, v) {
    n = document.createElementNS('http://www.w3.org/2000/svg', n);
    for (var p in v)
      n.setAttributeNS(null, p, v[p]);
    return n
  }

  calculateLength(points) {

    return [...points].reduce((partial, coords, i, arr) => {
      if (!i) return partial;

      partial += Math.abs(arr[i - 1].x - coords.x) + Math.abs(arr[i - 1].y - coords.y);

      return partial;
    }, 0);
  }
}
